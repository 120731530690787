import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Button from "../components/Button"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />

      <div className="flex flex-col justify-center items-center mr-6 h-screen">
        <h1 className="text-white mt-6 text-8xl font-black font-runescape">
          YOU ARE BEING SCAMMED
          <p className="text-white text-sm text-center">
            (You are at ground zero)
          </p>
        </h1>
        <p className="text-white text-xl mt-6">
          <ul className="list-disc">
            <li>Fair Launch</li>{" "}
            <li>
              Liquidty Locked until 3:33am December 31st 2099 (
              <a href="https://dxsale.app/app/v3/dxlockview?id=0&add=0x5aED268d7FB15c435A3C27a34C3A7394D8F8C814&type=lplock&chain=BSC">
                proof
              </a>
              )
            </li>{" "}
            <li>Renounced Ownership</li> <li>UNRUGGABLE</li>
            <li>
              Token Audit:&nbsp;
              <a href="https://tokensniffer.com/token/0x27d435e0bc8728dac3869e118f8e6c887574bc01">
                here
              </a>
            </li>
            <li>
              Contract:{" "}
              <a href="https://bscscan.com/token/0x27d435e0bc8728dac3869e118f8e6c887574bc01">
                0x27d435e0bc8728dac3869e118f8e6c887574bc01
              </a>
            </li>
          </ul>
        </p>
        <a
          className="mt-8"
          href="https://poocoin.app/tokens/0x27d435e0bc8728dac3869e118f8e6c887574bc01"
        >
          <Button>Buy on PooCoin Now</Button>
        </a>

        <a className="mt-8" href="https://t.me/SCAMCOIN_RUGPULL">
          <Button>Join the Telegram ASAP</Button>
        </a>

        <p className="mt-8 text-white">SCAMPAPER COMING SOON...</p>
      </div>
    </Layout>
  )
}

export default IndexPage
