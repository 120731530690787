import React, { useContext } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./Layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"></script> */}
        {/* <script src="https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.fog.min.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `VANTA.FOG({
            el: "#fog-bg",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            highlightColor: 0x4dff,
            midtoneColor: 0x6600ff,
            lowlightColor: 0x378bff,
            baseColor: 0x151d43,
            blurFactor: 0.90,
            speed: 1.50,
            zoom: 1.70
          })`,
          }}
        ></script> */}
      </Helmet>
      <div
        style={{
          background: "rgb(2,0,36)",
          background: "linear-gradient(180deg, #0e131d, #060a10 30.65%)",
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          {/* <Header account={account} siteTitle={data.site.siteMetadata.title} /> */}
          <main>{children}</main>
          {/* <footer class="flex flex-col p-6 mt-12 ">
            <Link class="text-gray-400" to="/">
              Home
            </Link>
            <Link class="text-gray-400" to="/">
              Buy Packs
            </Link>
            <Link class="text-gray-400" to="/">
              Open Packs
            </Link>
            <Link class="text-gray-400" to="/">
              About
            </Link>
            <a class="text-gray-400" href="https://github.com/redhair">
              Github
            </a>
            <Link class="text-gray-400" href="/battle">
              Battle
            </Link>
            <Link class="text-gray-400" href="/battle">
              Gallery
            </Link>
            <Link class="text-gray-400" href="/battle">
              Tournaments
            </Link>
            <br />
            <p className="text-gray-400">&copy; ethersplit 2021</p>
          </footer> */}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
